import React from 'react'
import {Link} from 'react-router-dom'
import TestimonialView from '../../../common/testimonial-view/testimonial-view'

const FirstProject = () => {
  return (
    <div className="py-7 py-md-8 overflow-hidden">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 mb-3 order-1 order-lg-0 text-left text-sm-center text-lg-left">
            <h2 className="mr-lg-5">More than just extra hands</h2>
            <div className="d-flex justify-content-start justify-content-sm-center justify-content-lg-start">
              <ul>
                <li>Develop Applications</li>
                <li>Create Infrastructure as Code</li>
                <li>Architecture Design</li>
                <li>Integration &amp; Deployment</li>
                <li>Managed Services</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 pt-5 order-0 order-lg-1">
            <img src="/assets/img/home/webdesign1.png" alt="project1" className="img-fluid scale-13 mb-5"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstProject
