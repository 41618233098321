import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import PeopleState from '../context/people/people-state'
import BlogState from '../context/blog/blog-state'

import Home from './pages/home/home'
import NoMatch from './common/no-match/no-match'
import Services from './pages/services/services'
import SingleService from './pages/single-service/single-service'
import Contact from './pages/contact/contact'

import '../assets/styles/main.scss'
import ScrollTop from './common/scroll-top/scroll-top'


const App = () => {
  return (
    <PeopleState>
      <BlogState>
        <Router>
          <ScrollTop>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/services" component={Services}/>
              <Route path="/projects/:slug" component={SingleService}/>
              <Route path="/contact" component={Contact}/>
              <Route component={NoMatch}/>
            </Switch>
          </ScrollTop>
        </Router>
      </BlogState>
    </PeopleState>
  )
}

export default App
