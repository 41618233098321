import React, {useEffect} from 'react'
import Navbar from '../../common/navbar/navbar'
import Footer from '../../common/footer/footer'
import './single-service.scss'

const SingleProject = () => {

  return (
    <div className="projects">
      <Navbar light/>

      <div className="project">

        <div className="py-7 py-md-8 d-flex justify-content-center align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h1>Deploy new Applications and Infrastructure to the Cloud
                </h1>
              </div>
              <div className="col-lg-4">
                <h4 className="mb-2">We have a long track record of successful infrastructure and application deployments on AWS.
                </h4>
                <br></br>
                <h5 className="mb-2">Strategy and roadmap:</h5>
                <p className="mb-4">To facilitate decisions such as how cloud can best be used for your organization, which applications are a best fit for cloud, and whether a proof of concept is needed.
                </p>
                <h5 className="mb-2">Architecture audit:</h5>
                <p className="mb-4">Design to gathering requirements, creating logical and network diagrams, creating cloud architecture documents.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer light/>
    </div>
  )
}

export default SingleProject