import React, {useContext, useEffect} from 'react'
import ProjectsContext from '../../../../context/projects/projects-context'
import ServicePreview from '../service-preview/project-preview'

const ServiceList = () => {
  const projectsContext = useContext(ProjectsContext)
  useEffect(() => {
    projectsContext.getProjects()
  }, [])

  const {projects, loading} = projectsContext

  if (loading) return <p className="text-center">Loading projects...</p>

  return (
    <React.Fragment>
      {projects.length && projects.map(project => <ServicePreview key={project.id} project={project} />)}
    </React.Fragment>
  )
}

export default ServiceList