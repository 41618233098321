import React from 'react'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './footer.scss'

import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons';

const Footer = ({light, short}) => {
  return (
    <footer className={`footer pb-6 pt-6 pt-md-8 ${light ? 'footer_light' : 'bg-primary text-white'}`}>
      <div className="container">
        {!short && (
          <div className="row">
            <div className="col-sm-7">
              <h2>Have a question? talk to us.</h2>
              <h5 className="mb-5 mb-md-7">Get started today.</h5>
            </div>
            <div className="col-sm-5 pt-3 mb-5 mb-md-0">
              <p className={`small mb-1 ${!light ? 'text-light' : 'text-muted'}`} >FOR BUSINESS</p>
              <p className="mb-4 pb-2 h5">
                <a className={!light ? 'text-white' : 'text-black'} href="javascript:void(0)">info@nitmedia.com</a>
              </p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-7 mb-3">© NitMedia. 2022</div>
          <div className="col-md-5 mb-3 text-white">
            <a className={`mr-5 ${!light && 'text-white'}`} href="https://twitter.com/meppurathu" target="_blank">
              <FontAwesomeIcon icon={faTwitter} size="lg"/>
            </a>
            <a className={`mr-5 ${!light && 'text-white'}`} target="_blank" href="https://www.linkedin.com/company/51687186/">
              <FontAwesomeIcon icon={faLinkedinIn} size="lg"/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
