import React from 'react'

const HomeHeader = () => {
  return (
    <header className="container hero text-md-center">

      <div className="my-auto">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <h4 className="mt-5">Web development and DevOps solutions and services</h4>
            <h1 className="">Automate away your inefficiencies, improve your software delivery, and build agility</h1>
          </div>
        </div>
      </div>
      <div className="op-5 mb-5">
        <h5 className="">They trust us</h5>
        <div className="row justify-content-md-center align-items-center overflow-x-auto">
          <div className="col-auto">
            <img src="/assets/img/brands/47-logo.png" alt="" height="44" className="mb-4"/>
          </div>
          <div className="col-auto">
            <img src="/assets/img/brands/Billboard.png" alt="" height="44" className="mb-4"/>
          </div>
          <div className="col-auto">
            <img src="/assets/img/brands/Hollywood-Reporter.png" alt="" height="44" className="mb-4"/>
          </div>
          <div className="col-auto">
            <img src="/assets/img/brands/vibe.svg" alt="" height="24" className="mb-4"/>
          </div>
          <div className="col-auto">
            <img src="/assets/img/brands/the-shade-store.png" alt="" height="74" className="mb-4"/>
          </div>
        </div>
      </div>

    </header>
  )
}

export default HomeHeader
